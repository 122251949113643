import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Style & Assets
import style from '../assets/style/Profile.module.css';

const Profile = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.user.verifiedEmail) {
            navigate("/verify")
        }
    },[navigate, props.user.verifiedEmail])

    return (
        <div className={style.profileDiv}>
            <h1>Profile Page</h1>
            <p>{props.user.username}</p>
        </div>
    );
};

export default Profile;