import React, {useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

// Style & Assets
import style from '../assets/style/SignUp.module.css';

const SignUp = (props) => {
    // Dependencies 
    const navigate = useNavigate();

    // Setup State
    const[username, setUsername] = useState('');
    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const[company, setCompany] = useState('');
    const[firstName, setFirstName] = useState('');
    const[lastName, setLastName] = useState('');
    const[experience, setExperience] = useState('1-3');
    const[role, setRole] = useState('Individual Contributor');

    const[dupEmail, setDupEmail] = useState(false);
    const[dupUsername, setDupUsername] = useState(false);

    function register(e) {
        e.preventDefault();

        // Make API Request
        axios({
            method: 'post',
            url: '/auth/register',
            withCredentials: true,
            data: {
              username: username,
              email: email,
              password: password,
              companyName: company,
              firstName: firstName,
              lastName: lastName,
              yearsExp: experience,
              salesRole: role
            }
          })
          .then(function(res) {
            // If Sign Up Successful Redirect
            if(res.status === 200) {    
                props.setUser(res.data)
                navigate('/profile')
            }
          })
          .catch(error => {
            setDupEmail(Boolean(error.response.data.emailDup))
            setDupUsername(Boolean(error.response.data.usernameDup))
          });
    }

    return (
        <div className={style.signUpPageDiv}>
            <h1>Sign Up</h1>
            <form className={style.signUpForm} onSubmit={register}>
                <label htmlFor='username'>Display Name</label>
                <input id="username" type="text" placeholder='Display Name...' required maxLength={45} minLength={3} onChange={e => setUsername(e.target.value)}  className={dupUsername ? style.error : ""}/>
                {dupUsername &&
                    <div className={style.errorText}><p>Display Name already in use</p></div>
                }

                <label htmlFor='email'>Email</label>
                <input id="email" type="email" placeholder='Email...' required autoComplete='email' onChange={e => setEmail(e.target.value)} className={dupEmail ? style.error : ""} />
                {dupEmail &&
                    <div className={style.errorText}><p>An account with this email already exists</p></div>
                }

                <label htmlFor='password'>Password</label>
                <input id="password" type="password" placeholder='Password...' required autoComplete='new-password' maxLength={45} minLength={6} onChange={e => setPassword(e.target.value)}/>

                <label htmlFor='firstName'>First Name</label>
                <input id="firstName" type="text" required placeholder='First Name...' autoComplete='given-name' maxLength={45} onChange={e => setFirstName(e.target.value)}/>

                <label htmlFor='lastName'>Last Name</label>
                <input id="lastName" type="text" required placeholder='Last Name...' autoComplete='family-name' maxLength={45} onChange={e => setLastName(e.target.value)}/>
                
                <label htmlFor='company'>Company Name</label>
                <input id="company" type="text" required placeholder='Company Name...' autoComplete='organization' maxLength={128} onChange={e => setCompany(e.target.value)}/>

                <label htmlFor='experience'>How many years of experience in sales do you have?</label>
                <select id="experience" onChange={e => setExperience(e.target.value)}>
                    <option value='1-3'>1-3</option>
                    <option value='4-7'>4-7</option>
                    <option value='8+'>8+</option>
                </select>

                <label htmlFor='role'>What is your sales role?</label>
                <select id="role" onChange={e => setRole(e.target.value)}>
                    <option value='Individual Contributor'>Individual Contributor</option>
                    <option value='Sales Management'>Sales Management</option>
                </select>

                <input type="submit" value="Sign Up"/>
            </form>
        </div>
    );
};

export default SignUp;