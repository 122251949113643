import React, { useState } from 'react';

// Style & Assets
import style from '../assets/style/Review.module.css';
import Stars from './Stars';
import CheckMark from '../assets/images/check.svg';
import Warning from '../assets/images/warning.svg';
import axios from 'axios';

const Review = (props) => {
    const[showReviewModal, setReviewModal] = useState(false);

    function deleteReview() {
        axios({
            method: 'get',
            url: '/api/deleteReview',
            withCredentials: true,
            params:
                {
                    reviewID: props.data.reviewID
                }
            }).then(function() {
                props.removeReviewFromList(props.data.reviewID)
            }).catch(error => {
                console.log("Error: " + error)
            });
    }

    return (
        <div>
            <div className={style.reviewDiv}>
                <div className={style.topBar}>
                    <h3>{props.data.userDisplayName}</h3>
                    <div className={style.starBlock}>
                        <Stars starCount={props.data.stars}/>
                        {props.user.permLevel !== 0 &&
                            <button onClick={e => {deleteReview()}}>X</button>
                        }
                    </div>
                </div>
                <div className={style.row2}>
                    <button onClick={e => {setReviewModal(true)}}>See Review Details</button>
                    {props.data.success 
                        ? <div className={style.successBox}><p>Sale Successful</p><img src={CheckMark} alt="Check Mark"/></div>
                        : <div className={style.successBox}><p>Sale not Successful</p><img src={Warning} alt="Warning Sign"/></div>
                    }
                </div>

            </div>
            {showReviewModal &&
                <div className='modal' onClick={e => {setReviewModal(false)}}>
                    <div className={style.reviewModalContent} onClick={e => e.stopPropagation()}>
                        <button className={style.closeBtn} onClick={e => {setReviewModal(false)}}>X</button>
                        <div className={style.topBar}>
                            <h3>{props.data.userDisplayName}</h3>
                            {props.data.success 
                                ? <div className={style.successBox}><p>Sale Successful</p><img className={style.starImage} src={CheckMark} alt="Check Mark"/><Stars starCount={props.data.stars}/></div>
                                : <div className={style.successBox}><p>Sale not Successful</p><img className={style.starImage}src={Warning} alt="Warning Sign"/><Stars starCount={props.data.stars}/></div>
                            }
                        </div>
                        <div className={style.infoBlock}>
                            <hr />
                            <p><b>Buyer Experience Level:</b> {props.data.expLevel}</p>
                            <p><b>Preferred Means of Communication:</b> {props.data.preferredCom}</p>
                            <p><b>Existing Vendor Sought to Replace:</b> {props.data.existingVendor}</p>
                            <br />
                            <p><b>Internal Champion Name:</b> {props.data.internalChampionName}</p>
                            <p><b>Internal Champion Role:</b> {props.data.internalChampionRole}</p>
                            <br />
                            <p><b>Decision Maker Name:</b> {props.data.decisionMakerName}</p>
                            <p><b>Decision Maker Role:</b> {props.data.decisionMakerRole}</p>
                            <br />
                            <p><b>Process Details:</b> {props.data.processDetails}</p>
                            <br />
                            <p><b>Additional Comments:</b> {props.data.comments}</p>

                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Review;