import React, {Fragment, useEffect, useState} from 'react';
import axios from 'axios';
import { useNavigate, Link, useLocation } from 'react-router-dom';

// Style & Assets
import style from '../assets/style/Navbar.module.css';
import HamburgerMenu from '../assets/images/hamburger.svg';

// Components
import SearchBar from './SearchBar';
import Login from './Login';

const Navbar = (props) => {
    const navigate = useNavigate();
    // State
    const[showLoginModal, setShowLoginModal] = useState(false);
    const[showSearch, setShowSearch] = useState(true);
    const[showHamburgerMenu, setHamburgerMenuState] = useState(false)

    //
    let location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") {
            setShowSearch(false)
        } else {
            setShowSearch(true)
        }
    },  [location])

    // Functions
    function toggleLoginModal() {
        setShowLoginModal(!showLoginModal)
    }

    function logOut() {
        // Make API Request
        axios({
            method: 'post',
            url: '/auth/logout',
            withCredentials: true
          })
          .then(function(res) {
            // If Login Successful Redirect
            if(res.status !== 200) {                
                console.log(res.status);
            }
            localStorage.removeItem('jwt')
            localStorage.removeItem('jwt-refresh')
            props.setUser(null);
            navigate('/')
          });
    }
        
    return (
        <nav className={style.navBar}>
            <div className={style.navBarContent}>
                <Link to="/" onClick={e => {setHamburgerMenuState(false)}}><img src={process.env.REACT_APP_API_URL+"/public/logo.png"} alt={"CrystalOpps Logo"} /></Link>
                <div>
                    {showSearch &&
                        <SearchBar />
                    }
                </div>
                <div className={style.buttonsBlock}>
                    <div className={style.desktop}>
                        {!props.user
                        ?
                        <>
                            <button onClick={toggleLoginModal}>Login</button>
                            <Link to="/signup"><button>Sign Up</button></Link>
                        </>
                        : 
                        <>
                            <Link to="/profile"><button>Profile</button></Link>
                            <button onClick={e => {logOut()}}>LogOut</button>
                        </>
                        }
                    </div>
                    <div className={style.mobile}>
                        <button onClick={e => {setHamburgerMenuState(!showHamburgerMenu)}}><img src={HamburgerMenu} alt="Hamburger Menu Icon" /></button>
                        {showHamburgerMenu &&
                            <div className={style.hamburgerMenu}>
                                {!props.user
                                ?
                                <>
                                    <button onClick={e=> {toggleLoginModal(); setHamburgerMenuState(false)}}>Login</button>
                                    <Link to="/signup" onClick={e => {setHamburgerMenuState(false)}}><button>Sign Up</button></Link>
                                </>
                                : 
                                <>
                                    <Link to="/profile"><button onClick={e => {setHamburgerMenuState(false)}}>Profile</button></Link>
                                    <button onClick={e => {logOut(); setHamburgerMenuState(false)}}>LogOut</button>
                                </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            {showLoginModal &&
                <Login toggleLoginModal={toggleLoginModal} setUser={props.setUser}/>
            }
        </nav>
    );
};

export default Navbar;