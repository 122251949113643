import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom'
import axios from 'axios';

// Components
import ReviewBlock from '../components/ReviewBlock';

// Style & Assets
import style from '../assets/style/Company.module.css';
import LeaveReview from '../components/LeaveReview';

const Company = (props) => {
    const[companyData, setCompanyData] = useState([]);
    const[companyFound, setCompanyFound] = useState(true);
    const {companyID} = useParams();
    const[reviewModal, toggleLeaveReviewModal] = useState(false);
    const[alreadyLeftReview, setAlreadyLeftReview] = useState(false);

    useEffect(() => {
        // Make API Request
        axios({
            method: 'get',
            url: '/api/getCompanyData',
            withCredentials: true,
            params: 
                {
                    companyID: companyID
                }
            })
            .then(function(res) {
                if (res.status === 200) {
                    // Set State
                    setCompanyData(res.data);  
                    setCompanyFound(true);
                } else {
                    // Handle no Company Found
                    setCompanyFound(false);
                }
            })
            .catch(error => {
                setCompanyFound(false);
            }
        );

        // Check if user has already left review
        axios({
            method: 'get',
            url: '/api/alreadyLeftReview',
            withCredentials: true,
            params: 
                {
                    companyID: companyID
                }
            })
            .then(function(res) {
                if (res.data === true) {
                    setAlreadyLeftReview(true)
                }
            })
            .catch(error => {
                console.log(error)
            }
        );
    }, [companyID]);

    return (
        <div className={style.companyPageDiv}>
            {!companyFound
            ?
                <h2>No Company Found</h2>
            :
            <>
                <h1>{companyData.companyName}</h1>
                <h2>{companyData.companyURL}</h2>

                <div className={style.reviewBlock}>
                    <div className={style.topBlock}>
                        <h2>Reviews</h2>
                        {(props.user && !alreadyLeftReview)  && 
                            <button onClick={e => {toggleLeaveReviewModal(true)}} className={style.leaveReviewBtn}>Leave Review</button>
                        }
                    </div>
                    <div className={style.reviewContent}>
                        {(!props.user || !props.user.leftReview)
                            ? 
                            <>
                            {!props.user 
                            ? <Link to="/signup"><button>Sign Up or Login to See Reviews</button></Link>
                            : <p>You must leave a review on any company's page before viewing reviews from other users</p>
                            }
                            </>
                            : <ReviewBlock companyID={companyID} user={props.user}/>
                        }
                    </div>
                </div>
            </>   
            }
            {(reviewModal && props.user) &&
                <LeaveReview companyID={companyID} toggleLeaveReviewModal={toggleLeaveReviewModal} refresh={props.refresh} />
            }
        </div>
    );
};

export default Company;