import React, { useEffect, useState } from 'react';
import SearchResult from '../components/SearchResult';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/SearchResults.module.css';
import { Link, useSearchParams } from 'react-router-dom';
import Spinner from '../components/Spinner';

const SearchResults = (props) => {
    // State
    const[searchParams] = useSearchParams();
    const[results, setResults] = useState([]);

    // No Results
    const[reqCompany, setReqCompany] = useState('');
    const[reqURL, setReqURL] = useState('');
    const[message, setMessage] = useState('');

    //
    const[loading, setLoading] = useState(true);


    useEffect(() => {
        let searchString = searchParams.get("q");

        // Make API Request
        axios({
            method: 'get',
            url: '/api/search',
            withCredentials: true,
            params: 
                {
                    q: searchString
                }
            })
            .then(function(res) {
                if (res.status === 200) {
                    // Set State
                    setResults(res.data);  
                }   
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            }
        );

    }, [searchParams])

    function requestCompany(e) {
        e.preventDefault();

        // Make API Request
        axios({
            method: 'post',
            url: '/api/requestCompany',
            withCredentials: true,
            data: {
                companyName: reqCompany,
                companyWebsite: reqURL
            }
        }).then(function(res) {
            setMessage(reqCompany + " has been requested, you will receive an email if/when it is added")
        }).catch(error => setMessage("There has been an error requesting that company, please try again later"))
    }

    if (loading) {
        return <Spinner />
    }

    return (
        <div className={style.resultsPageDiv}>
            {!results 
            ?
                <div className={style.noResults}>
                    <h2>Don't see what you're looking for? Be the first to review</h2>
                    <hr />
                    {props.user
                    ?
                    <>
                        {!message
                        ? 
                            <>
                                <h3>Request a company be added</h3>
                                <form onSubmit={requestCompany}>
                                    <label htmlFor='reqCompanyName'>Company Name</label>
                                    <input id='reqCompanyName' type="text" placeholder='Company Name' required minLength={1} onChange={e => {setReqCompany(e.target.value)}} />

                                    <label htmlFor='reqCompanyWebsite'>Company Website</label>
                                    <input id='reqCompanyWebsite' type="text" placeholder='Company Website URL' onChange={e => {setReqURL(e.target.value)}}/>

                                    <button>Request</button>
                                </form>
                            </>
                        :
                            <>
                                <p>Company Requested, you will receive an email if/when the company is added</p>
                            </>
                        }
                    </>
                    :
                    <>
                        <h3>Sign Up to Request to add a company</h3>
                        <Link to="/signup"><button>Sign Up</button></Link>
                    </>
                    }
                </div>
            : 
            <>
                {!props.user &&
                    <h2 className={style.warning}>To see reviews you will need to sign up<br/><Link to="/signup"><button>Sign Up</button></Link>                    </h2>
                }
                {results.map((result) =>
                    <SearchResult key={result.companyID.toString()} data={result} />
                )}
            </>
            }
        </div>
    );
};

export default SearchResults;