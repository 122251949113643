import React, { useState } from 'react';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/CompanyRequest.module.css';


const CompanyRequest = (props) => {
    // Setup State
    const[handled, setHandled] = useState('')

    const[name, setName] = useState(props.data.companyName)
    const[web, setWeb] = useState(props.data.companyWebsite)

    // Approve Request
    function approveRequest() {
        axios({
            method: 'post',
            url: '/api/approveCompanyRequest',
            withCredentials: true,
            data:
                {   
                    requestID: props.data.requestID,
                    companyName: name,
                    companyWebsite: web,
                    requestedByID: props.data.requestedByID
                }
            }).then(function(res) {
                setHandled("Company Request Approved -- Company Added")
            }).catch(error => {
                console.log("Error: " + error)
                setHandled("ERROR: " + error)
            });
    }

    // Deny Request
    function denyRequest() {
        axios({
            method: 'post',
            url: '/api/denyCompanyRequest',
            withCredentials: true,
            data:
                {   
                    requestID: props.data.requestID,
                }
            }).then(function(res) {
                setHandled("Company Request Denied")
            }).catch(error => {
                console.log("Error: " + error)
                setHandled("ERROR: " + error)
            });
    }

    return (
        <div className={style.requestBox}>
            <p><b>Company Name:</b> <input value={name} onChange={e => {setName(e.target.value)}} /></p>
            <p><b>Company Website:</b><input value={web} onChange={e => {setWeb(e.target.value)}} /></p>
            {!handled
            ?
                <div className={style.buttonBox}>
                <button onClick={e => {approveRequest()}} className="yes">Approve</button>
                <button onClick={e => {denyRequest()}} className="no">Reject</button>
                </div>
            : <p>{handled}</p>
            }
        </div>
    );
};

export default CompanyRequest;