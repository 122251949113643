import React, { useState } from 'react';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/LeaveReview.module.css';
import { useNavigate } from 'react-router-dom';

const LeaveReview = (props) => {
    // Setup State
    const[stars, setStars] = useState(1);
    const[success, setSuccess] = useState(false);
    const[expLevel, setExpLevel] = useState('');
    const[com, setCom] = useState('Phone Call');
    const[prevVendor, setPrevVendor] = useState('');
    const[intC, setIntC] = useState('');
    const[intCRole, setIntCRole] = useState('');
    const[dmName, setDmName] = useState('');
    const[dmRole, setDmRole] = useState('');
    const[process, setProcess] = useState('');
    const[comments, setComments] = useState('');

    const[error, setError] = useState('');

    const navigate = useNavigate();

    async function LeaveReview(e) {
        e.preventDefault();
        axios({
            method: 'post',
            url: '/auth/refresh',
            withCredentials: true,
            })
            .then(function(res) {
                SendReviewToServer();
            })
            .catch(error => {
              // If 401 Ignore
              if (error.response.status === 401) {
                  return
              }
              return
          });
    }

    function SendReviewToServer() {
        // Make API Request
        axios({
            method: 'post',
            url: '/api/leaveReview',
            withCredentials: true,
            data: {
                companyID: parseInt(props.companyID),
                stars: parseInt(stars),
                success: success,
                expLevel: expLevel,
                preferredCom: com,
                existingVendor: prevVendor,
                internalChampionName: intC,
                internalChampionRole: intCRole,
                decisionMakerName: dmName,
                decisionMakerRole: dmRole,
                processDetails: process,
                comments: comments,
            }
            })
            .then(function(res) {
            // If Sign Up Successful Redirect
            if(res.status === 200) {    
                navigate(0)
            } else {
                setError(true)
            }
            })
            .catch(error => {
            setError(true)
            });
    }

    return (
        <div className={style.modal}>
            <div className={style.leaveReviewContent}>
                <button onClick={e => {props.toggleLeaveReviewModal(false)}}>X</button>
                <form onSubmit={LeaveReview}>
                    <h2>Fill out Review Details</h2>
                    <h4>Your review must be approved before it is visible</h4>
                    
                    <label htmlFor='starSelector'>Rate the company on a scale of 1 to 5 stars (with 5 being the highest)</label>
                    <input id='starSelector' type="number" max={5} min={1} defaultValue={1} onChange={e => {setStars(e.target.value)}}/>

                    <div className={style.sameLine}>
                        <label id='success'>Was the sale a success?</label>
                        <input id='success' type="checkbox" onChange={e => {setSuccess(!success)}} />
                    </div>
                    
                    <label htmlFor='exp'>What was the experience level of the buyer?</label>
                    <input id='exp' type="text" placeholder='Experience Level...' required onChange={e => {setExpLevel(e.target.value)}} maxLength={45}/>

                    <label htmlFor='com'>What were the preferred means of communication for the prospect?</label>
                    <select id='com' required onChange={e => {setCom(e.target.value)}}>
                        <option value='Phone Call'>Phone Call</option>
                        <option value='Email'>Email</option>
                        <option value='Text'>Text</option>
                        <option value='LinkedIn'>LinkedIn</option>
                        <option value='Face to Face'>Face to Face</option>
                    </select>

                    <label htmlFor='ven'>Who was the existing vendor you sought to replace?</label>
                    <input id='ven' type="text" placeholder='Previous Vendor...' required onChange={e => {setPrevVendor(e.target.value)}} maxLength={128}/>

                    <label htmlFor='icName'>Who was the internal champion within the organization?</label>
                    <input id='icName' type="text" placeholder='Their name...' required onChange={e => {setIntC(e.target.value)}} maxLength={128}/>

                    <label htmlFor='icRole'>What is their role?</label>
                    <input id='icRole' type="text" placeholder='Their role...' required onChange={e => {setIntCRole(e.target.value)}} maxLength={128}/>

                    <label htmlFor='dmName'>Who was the decision maker within the organization?</label>
                    <input id='dmName' type="text" placeholder='Their name...' required onChange={e => {setDmName(e.target.value)}} maxLength={128}/>

                    <label htmlFor='dmRole'>What is their role?</label>
                    <input id='dmRole' type="text" placeholder='Their role...' required onChange={e => {setDmRole(e.target.value)}} maxLength={128}/>

                    <label>Add all process details that applied to the sale (legal, procurement, etc.)</label>
                    <textarea onChange={e => {setProcess(e.target.value)}} required maxLength={1000}/>

                    <label>Additional Comments</label>
                    <textarea onChange={e => {setComments(e.target.value)}} required maxLength={1000}/>

                    <input type="submit" value="Leave Review"/>
                    {error &&
                        <p>There was an error creating your review, please check your inputs and try again</p>
                    }
                </form>
            </div>
        </div>
    );
};

export default LeaveReview;