import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Routes, Route, Navigate} from "react-router-dom";

// Components
import Navbar from './components/Navbar';
import Admin from './pages/Admin';

// Pages
import Company from './pages/Company';
import Landing from './pages/Landing';
import PasswordReset from './pages/PasswordReset';
import Profile from './pages/Profile';
import SearchResults from './pages/SearchResults';
import SignUp from './pages/SignUp';
import Verify from './pages/Verify';


function App() {
  // State
  const[user, setUser] = useState(null)
  const[loading, setLoading] = useState(true)

  function refresh() {
    axios({
      method: 'post',
      url: '/auth/refresh',
      withCredentials: true,
      })
      .then(function(res) {
      if (res.status === 200) {
          // Set State
          setUser(res.data);
          console.log(res.data)
          setLoading(false)
        }   
      })
      .catch(error => {
        setLoading(false)
        // If 401 Ignore
        if (error.response.status === 401) {
            return
        }
        return
    });
  }
  
  useEffect(() => {
    // Make API Request
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div></div>
  }
  return (
    <div className="mainDiv">
      <Navbar user={user} setUser={setUser} />
      <div className="content">
        <Routes>
          <Route path="/" exact element={<Landing user={user} />} />
          <Route path="/search" element={<SearchResults user={user} />} />
          <Route path="/company/:companyID" element={<Company user={user} refresh={refresh} />} />
          <Route path="/signup" element={<SignUp user={user} setUser={setUser} refresh={refresh} />} />
          <Route path="/profile" element={<Profile user={user} setUser={setUser} refresh={refresh}/>}/>
          <Route path="/admin" element={<Admin user={user} setUser={setUser} refresh={refresh} />} />
          <Route path="/verify" element={<Verify user={user} refresh={refresh}/>} />
          <Route path="/resetPassword" element={<PasswordReset />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;