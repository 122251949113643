import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Style & Assets
import style from '../assets/style/Landing.module.css';
import SearchBar from '../components/SearchBar';

const dynamicStyle = {
    background: '#55607C url('+process.env.REACT_APP_API_URL+'/public/background.png)',
    backgroundPositionY: 'center',
    BackgroundSize: 'cover',
 }

 
const Landing = (props) => {
    const[data, setData] = useState({headerText1: "", headerText2: "", paragraphText1: ""})

    useEffect(() => {
        axios({
            method: 'get',
            url: '/getData'            
            })
            .then(function(res) {
                setData(res.data[0])
            })
            .catch(error => {
                console.log(error)
            });
    }, [])

    return (
        <div>
            <div className={style.mainImageBlock} style={dynamicStyle}>
                <div className={style.centeredInBlock}>
                    <h1>{data.headerText1}</h1>
                    <SearchBar />
                </div>
            </div>
            <div className={style.infoBlock}>
                <h2>{data.headerText2}</h2>
                <p className="display-linebreak">{data.paragraphText1}</p>
                {!props.user &&
                    <Link to="/signup"><button>Get Started</button></Link>
                }
            </div>
        </div>
    );
};

export default Landing;