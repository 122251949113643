import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/SearchResult.module.css';

const SearchResult = (props) => {
    const[message, setMessage] = useState('');

    useEffect(() => {
        if (!props.user) {
            setMessage("Sign Up to view average review")
        }

        axios({
            method: 'get',
            url: '/api/getAverageReview',
            withCredentials: true,
            params: 
                {
                    companyID: props.data.companyID
                }
            })
            .then(function(res) {
                if (res.status !== 200) {
                    setMessage('')
                    return
                }

                let reviewText = "reviews"
                if (res.data.numReviews === 1) {
                    reviewText = "review"
                }
                let temp = props.data.companyName + ' has ' + res.data.numReviews + " " + reviewText + " with an average rating of " + res.data.averageRating + "/5 stars";

                setMessage(temp)
            })
            .catch(error => {
                setMessage(" ")
            });
    }, [props.data.companyID, props.data.companyName, props.user])

    return (
        <div className={style.searchResultDiv}>
            <div>
                <h1>{props.data.companyName}</h1>
                <h2>{props.data.companyURL}</h2>
            </div>
            <p>{message}</p>
            <br />    
            <Link to={"/company/"+props.data.companyID}><button>View Reviews</button></Link>
        </div>
    );
};

export default SearchResult;