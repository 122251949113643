import React, { useEffect, useState } from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/Verify.module.css';

const Verify = (props) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const[sentNew, setSentNew] = useState(false);
    const[isVerified, setVerified] = useState(false);
    const[verifyMessage, setVerifyMessage] = useState("Verification link not found or expired")

    useEffect(() => {
        let code = searchParams.get("code")
        let email = searchParams.get("email")

        if (!code || !email) {
            setVerifyMessage("Please verify your email before proceeding")
        }
        
        axios({
            method: 'get',
            url: '/auth/verify',
            params: 
            {
                code: code,
                email: email
            },
            })
            .then(function(res) {
                if (res.status === 200) {
                    setVerified(true)
                    props.refresh();
                }   
            });

    }, [props, searchParams])

    function sendNewVerificationEmail() {
        let email = searchParams.get("email")

        // If Signed in Use that email
        if (props.user) {
            email = props.user.email
        }

        axios({
            method: 'get',
            url: '/auth/sendNewEmail',
            params: 
            {
                email: email
            },
            })
            .then(function(res) {
                setSentNew(true);
            });
    }

    return (
        <div className={style.verifyDiv}>
            {(!sentNew && !isVerified) &&
                <div>
                    <h2>{verifyMessage}</h2>
                    <button onClick={sendNewVerificationEmail}>Send New Link</button>
                </div>
            }
            {(sentNew && !isVerified) &&
                <div><h2>Check your email for a new verification link</h2></div>
            }
            {isVerified &&
                <div>
                    <h2>Your Account is Verified!</h2>
                    <button onClick={e => {navigate("/")}}>Start Searching</button>
                </div>
            }

        </div>
    );
};

export default Verify;