import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';

// Style & Assets
import style from '../assets/style/PasswordReset.module.css'

const PasswordReset = () => {
    const [urlParam, setUrlPAram] = useSearchParams();
    const [code] = useState(urlParam.get("code"))

    const[password,setPassword] = useState('')
    const[confirmPassword, setConfirmPassword] = useState('')

    const[isError, setIsError] = useState(false)
    const[errorMsg, setError] = useState('')

    const[success, setSuccess] = useState(false)

    function resetPassword(e) {
        e.preventDefault();

        // Verify Passwords Match
        if (password !== confirmPassword) {
            setError("Passwords to not match")
            setIsError(true)
            return
        }

        axios({
            method: 'post',
            url: '/auth/resetPassword',
            data : {
                code: code,
                password: password
            }
        }).then(function(res) {
            setSuccess(true)
        }).catch(error => {
            setError("That code is not valid or expired")
            setIsError(true)
        });
    }

    return (
        <div>
            {!success
            ?
                <form className={style.resetForm} onSubmit={e => {resetPassword(e)}}>
                    <h3>Reset Password</h3>
                    <label htmlFor='password'>New Password</label>
                    <input id="password" type="password" placeholder='New Password' required autoComplete='new-password' maxLength={45} minLength={6} onChange={e => setPassword(e.target.value)}/>
                
                    <label htmlFor='confirmPassword'>Confirm Password</label>
                    <input id="confirmPassword" type="password" placeholder='Confirm Password' required autoComplete='confirm-password' maxLength={45} minLength={6} onChange={e => setConfirmPassword(e.target.value)}/>
                
                    <input type="Submit" value="Reset Password" />

                    {isError && 
                        <p>{errorMsg}</p>
                    }
                </form>
            :
                <div className={style.center}>
                    <h3>Password Reset Successful</h3>
                </div>
            }
        </div>
    );
};

export default PasswordReset;