import React, { useEffect, useState } from 'react';

// Style & Assets
import style from '../assets/style/Stars.module.css';
import starFilled from '../assets/images/starFilled.png';
import startNotFilled from '../assets/images/starNotFilled.png';

const Stars = (props) => {
    const[loading, setLoading] = useState(true);
    const[starImages, setStarImages] = useState([])


    useEffect(() => {
        for (let i  = 0; i < 5; i++) {
            if (i < props.starCount) {
                let temp = starImages
                let data = {
                    "img": starFilled,
                    "alt": "Filled Star"
                }
                temp.push(data)
                setStarImages(temp)
            } else {
                let temp = starImages
                let data = {
                    "img": startNotFilled,
                    "alt": "Unfilled Star"
                }
                temp.push(data)
                setStarImages(temp)
            }
        }

        setLoading(false)
    },[props.starCount, starImages])

    if (loading) {
        return <div></div>
    }

    return (
        <div className={style.starsDiv}>
            <img src={starImages[0].img} alt={starImages[0].alt} />
            <img src={starImages[1].img} alt={starImages[1].alt} />
            <img src={starImages[2].img} alt={starImages[2].alt} />
            <img src={starImages[3].img} alt={starImages[3].alt} />
            <img src={starImages[4].img} alt={starImages[4].alt} />
        </div>
    );
};

export default Stars;