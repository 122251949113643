import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';

// Style & Assets
import style from '../assets/style/SearchBar.module.css';
import SearchImg from '../assets/images/search.svg';

const SearchBar = () => {
    const[searchParam, setSearchParam] = useState('')
    const navigate = useNavigate();

    const[suggested, setSuggested] = useState([])

    function search(e) {
        e.preventDefault();
        // If Search Param Empty
        if (!searchParam) {
            return
        }

        let withoutSpace = searchParam.replace(/ /g,"");
        if (withoutSpace.length === 0) {
            return
        }

        // Generate Search String
        let searchString = "";

        // Split words using + sign
        const words = searchParam.split(" ");
        for (let i = 0; i < words.length; i++) {
            // If not last word
            if (i !== words.length-1) {
                searchString += words[i] + "+"
            }
            // If last word
            if (i === words.length-1) {
                searchString += words[i]
            }
        }

        // Update Search Params
        navigate("/search?q="+searchString)
    }

    function updateAutoComplete(searchText) {
        let withoutSpace = searchText.replace(/ /g,"");
        if (withoutSpace.length < 2) {
            setSuggested([])
            return
        }

        axios({
            method: 'get',
            url: '/api/search',
            withCredentials: true,
            params: 
                {
                    q: searchText
                }
            })
            .then(function(res) {
                if (res.status === 200) {
                    setSuggested(res.data);  
                }   
            })
            .catch(error => {
                console.log(error)
            }
        );
    }

    return (
        <form className={style.searchBar} onSubmit={search}>
            <label htmlFor='companySearch'>Search a Company</label>
            <input id="companySearch" onChange={e => {setSearchParam(e.target.value); updateAutoComplete(e.target.value)}} type="text" placeholder='Search a company...' autoComplete='off'/>
            <input type="image" src={SearchImg} alt={"Search"} />
            {(suggested && suggested.length > 0) &&
            <div className={style.autoComplete}>
                {suggested.map((company) =>
                    <div className={style.autoCompleteSuggestion} key={company.companyID}><Link to={"/company/"+company.companyID}><p>{company.companyName} ({company.companyURL})</p></Link></div>
                )}
            </div>
            }
        </form>
    );
};

export default SearchBar;