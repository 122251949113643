import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Style & Assets
import CompanyRequest from './CompanyRequest';

const CompanyRequests = (props) => {
    const[requests, setRequests] = useState([]);

    useEffect(() => {
        axios({
            method: 'get',
            url: '/api/getRequests',
            withCredentials: true
            }).then(function(res) {
                setRequests(res.data)
            }).catch(error => {
                console.log("Error: " + error)
            });
    }, [])

    return (
        <div>
            <h3>Company Requests</h3>
            {!requests
            ?
                <h4>No new requests to approve</h4>
            : 
                <>
                {requests.map((request) =>
                    <CompanyRequest key={request.requestID.toString()} data={request} />
                )}
                </>
            }
        </div>
    );
};

export default CompanyRequests;