import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

// Style & Assets
import style from '../assets/style/Login.module.css';

const Login = (props) => {
    // Dependencies
    const navigate = useNavigate()

    // State
    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const[remember, setRemember] = useState(false);
    const[isError, setError] = useState(false);
    const[showResetBox, setShowResetBox] = useState(false)
    const[hasResetPassword, setHasResetPassword] = useState(false)

    function forgotPassword(e) {
        e.preventDefault();

        axios({
            method: 'post',
            url: '/auth/sendResetPassword',
            data : {
                email: email
            }
        }).then(function(res) {
            setHasResetPassword(true)
        })
    }

    function login(e) {
        e.preventDefault();
        console.log("password: " + password)
        // Make API Request
        axios({
            method: 'post',
            url: '/auth/login',
            withCredentials: true,
            data: {
              email: email,
              pass: password,
              remember: remember
            }
          })
          .then(function(res) {
            // If Login Successful Redirect
            if(res.status === 200) {    
                props.setUser(res.data)
                props.toggleLoginModal();
                console.log("Navigating")
                navigate(0)  
            } else {
                // Handle Error
                setError(true);
            }
          })
          .catch(error => {
            setError(true);
          });
    }

    return (
        <div className="modal" onClick={e => {props.toggleLoginModal()}}>
            <div className="modalContent" onClick={e => e.stopPropagation()}>
                {!showResetBox
                ?
                <>
                    <div className={style.topBar}>
                        <h2>Login</h2>
                        <button onClick={e => {props.toggleLoginModal()}}>X</button>
                    </div>
                    <form className={style.loginForm} onSubmit={login}>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" onChange={e => {setEmail(e.target.value)}} placeholder='Email...' required autoComplete='email'/>

                        <label htmlFor='password'>Password</label>
                        <input id="password" type="password" onChange={e => {setPassword(e.target.value)}} required autoComplete='password' placeholder='Password...'/>

                        <label htmlFor='rememberMe'>Remember Me</label>
                        <input id="rememberMe" onChange={e => setRemember(!remember)} type="checkbox" />

                        <input type="submit" value="Login"/>
                        {isError &&
                            <div className={style.error}><p>Incorrect email or Password</p></div>
                        }
                    </form>

                    <div className={style.bottomBar}>
                        <button onClick={e => {setShowResetBox(true)}}>Forgot Password?</button>
                        <Link to="/signup"><button onClick={e => {props.toggleLoginModal()}}>Sign Up</button></Link>
                    </div>
                </>
                :
                <>
                    <div className={style.topBar}>
                        <h2>Reset Password</h2>
                        <button onClick={e => {props.toggleLoginModal()}}>X</button>
                    </div>
                    {!hasResetPassword
                    ? 
                    <>
                        <form className={style.loginForm} onSubmit={e => {forgotPassword(e)}}>
                            <label htmlFor="resetPassEmail">Email</label>
                            <input id="resetPassEmail" type="email" onChange={e => {setEmail(e.target.value)}} required autoComplete='emil' placeholder='Email...'/>
                            <input type="submit" value="Reset Password"/>
                        </form>
                    </>
                    :
                    <>
                        <p>Check your inbox for a link to reset your password</p>
                    </>
                    }
                </>
                }
            </div>
        </div>
    );
};

export default Login;