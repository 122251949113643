import { useState } from "react";
import Loader from "react-spinners/DotLoader";
import style from '../assets/style/Spinner.module.css';

const override = {
    display: "block",
    margin: "0 auto",
};


const Spinner = () => {
    let [color] = useState('#0069E0');

    return (
    <div className={style.modal}>
        <div style={{'marginTop': '10%'}}>
            <Loader color={color} cssOverride={override} size={175} className={style.modalContent} />
            <p style={{'display': 'none'}}>Loading...</p>
        </div>
    </div>);
};

export default Spinner;