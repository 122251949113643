import React from 'react';
import axios from 'axios';

// Style & Components
import style from '../assets/style/AdminReview.module.css';


const AdminReview = (props) => {
    function approveReview() {
        axios({
            method: 'post',
            url: '/api/approveReview',
            withCredentials: true,
            data: 
                {
                    reviewID: props.data.reviewID
                }
            }).then(function() {
                props.updateReviews();
            }).catch(error => {
                console.log("Error: " + error)
            });
    }

    function rejectReview() {
        axios({
            method: 'post',
            url: '/api/rejectReview',
            withCredentials: true,
            data: 
                {
                    reviewID: props.data.reviewID
                }
            }).then(function() {
                props.updateReviews();
            }).catch(error => {
                console.log("Error: " + error)
            });
    }

    return (
        <div className={style.adminReview}>
            <p><b>ReviewID:</b> {props.data.reviewID}</p>
            <p><b>Reviewer:</b> {props.data.userDisplayName}</p>
            <p><b>For Company:</b> {props.data.companyName}</p>
            <hr />
                <p><b>Stars:</b> {props.data.stars}</p>
                <p><b>Success:</b> {props.data.success ? <>Yes</> : <>No</>}</p>
                <br />
                <p><b>Buyer Experience Level:</b> {props.data.expLevel}</p>
                <p><b>Preferred Means of Communication:</b> {props.data.preferredCom}</p>
                <p><b>Existing Vendor Sought to Replace:</b> {props.data.existingVendor}</p>
                <br />
                <p><b>Internal Champion Name:</b> {props.data.internalChampionName}</p>
                <p><b>Internal Champion Role:</b> {props.data.internalChampionRole}</p>
                <br />
                <p><b>Decision Maker Name:</b> {props.data.decisionMakerName}</p>
                <p><b>Decision Maker Role:</b> {props.data.decisionMakerRole}</p>
                <br />
                <p><b>Process Details:</b> {props.data.processDetails}</p>
                <br />
                <p><b>Additional Comments:</b> {props.data.comments}</p>
            <hr />
            <div>
                <button className={style.approve} onClick={e => {approveReview()}}>Approve</button>
                <button className={style.reject} onClick={e => {rejectReview()}}>Reject</button>
            </div>
        </div>
    );
};

export default AdminReview;