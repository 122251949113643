import React, {useState, useEffect} from 'react';
import axios from 'axios';

// Components
import Review from './Review';

const ReviewBlock = (props) => {
    // State
    const[reviews, setReviews] = useState([]);

    // Get Reviews
    useEffect(() => {
        // Make API Call
        axios({
            method: 'get',
            url: '/api/getReviews',
            withCredentials: true,
            params: 
                {
                    companyID: props.companyID
                }
            })
            .then(function(res) {
                if (res.status === 200) {
                    // Set State
                    setReviews(res.data);  
                }
            })
            .catch(error => {
                console.log(error)
            }
        );


    }, [props.companyID])

    function removeReviewFromList(reviewID) {
      // Filter Feed
      let newReviews = reviews.filter(function(review) { 
        return review.reviewID !== reviewID;  
      });

      // Update State
      setReviews(newReviews)
    }

    // Render
    return (
        <>
            {!reviews
            ? 
                <p>No reviews found for this company</p>
            :
                <>
                {reviews.map((review) =>
                    <Review key={review.reviewID.toString()} data={review} user={props.user} removeReviewFromList={removeReviewFromList}/>
                )}
                </>
            }
        </>
    );
};

export default ReviewBlock;