import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Components
import AdminReview from '../components/AdminReview';

// Style & Assets
import style from '../assets/style/Admin.module.css';
import CompanyRequests from '../components/CompanyRequests';

const Admin = (props) => {
    // State
    const[reviews, setReviews] = useState([]);
    
    //
    const navigate = useNavigate();

    const[data, setData] = useState([{headerText1: "", headerText2: "", paragraphText1: ""}])
    const[logo, setLogo] = useState(null);
    const[header, setHeader] = useState(null);

    const[dataMsg, setDataMsg] = useState(null);
    const[logoMsg, setLogoMsg] = useState(null);
    const[headerMsg, setHeaderMsg] = useState(null);

    
    useEffect(() => {
        if (!props.user || props.user.permissionLevel < 1) {
            navigate('/')    
        }

        axios({
            method: 'get',
            url: '/getData',
            withCredentials: true            
            })
            .then(function(res) {
                setData(res.data)
                console.log(res.data)
            })
            .catch(error => {
                console.log(error)
            });
    }, [navigate])

    function getReviews() {
        // Get Reviews that Need Approving
        axios({
            method: 'get',
            url: '/api/getReviewsToApprove',
            withCredentials: true
            }).then(function(res) {
                setReviews(res.data)
            }).catch(error => {
                console.log("Error: " + error)
                if (error.response.status === 401) {
                    //navigate('/')
                }
            });
    }

    const getReviewsCallback = useCallback(
        () => {
            getReviews();
        }, []
    );

    useEffect(() => {
        getReviewsCallback();

    },[getReviewsCallback])

    function updateData(key, value) {
        let temp = [...data]
        temp[0][key] = value
        setData(temp)
    }

    function updateLogo(e) {
        e.preventDefault();

        const formData = new FormData() 
        formData.append('file', logo)
 
        axios({
            method: 'post',
            url: '/uploadLogo',
            withCredentials: true,
            headers: { "Content-Type": "multipart/form-data" },
            data: formData
          })
          .then(function(res) {
            console.log("Saved")
            setLogoMsg("Saved!")
          })
          .catch(error => {
            console.log(error.response)
            setLogoMsg(error)
          });
    }

    function updateHeader(e) {
        e.preventDefault();
        
        const formData = new FormData() 
        formData.append('file', header)
 
        axios({
            method: 'post',
            url: '/uploadHeader',
            withCredentials: true,
            headers: { "Content-Type": "multipart/form-data" },
            data: formData
          })
          .then(function(res) {
            console.log("Saved")
            setHeaderMsg("Saved!")
          })
          .catch(error => {
            console.log(error.response)
            setHeaderMsg(error)
          });
    }

    function saveData(e) {
        e.preventDefault();

        axios({
            method: 'post',
            url: '/updateData',
            withCredentials: true,
            data: 
                {
                    headerText1: data[0].headerText1,
                    headerText2: data[0].headerText2,
                    paragraphText1: data[0].paragraphText1
                }
            }).then(function(res) {
                console.log("SAVED")
                setDataMsg("Saved!")
            }).catch(error => {
                console.log("Error: " + error)
                setDataMsg(error)
            });
    }

    return (
        <div className={style.adminDiv}>
            <h3>Reviews to Approve</h3>
            {!reviews
            ?
                <h4>No new reviews to approve</h4>
            : 
                <>
                {reviews.map((review) =>
                    <AdminReview key={review.reviewID.toString()} data={review} updateReviews={getReviewsCallback} />)
                }
                </>
            }
            <br />
            <hr />
            <CompanyRequests user={props.user}/>

            <br />
            <hr />
            <h3>Edit Front Page Data</h3>
            <form className={style.editDataForm} onSubmit={saveData}>
                <label>Header 1</label>
                <input type="text" value={data[0].headerText1} onChange={e => {updateData("headerText1", e.target.value)}}/>

                <label>Header 2</label>
                <input type="text" value={data[0].headerText2} onChange={e => {updateData("headerText2", e.target.value)}} />

                <label>Paragraph Text</label>
                <textarea value={data[0].paragraphText1} onChange={e => {updateData("paragraphText1", e.target.value)}}/>

                <input type="submit" value="Save" />
                {dataMsg &&
                    <p>{dataMsg}</p>
                }
            </form>

            <br />
            <hr />

            <h3>Edit Logo</h3>
            <form onSubmit={updateLogo}>
                <input type="file" accept="image/*" onChange={(e) => setLogo(e.target.files[0])}/>
                <input type="submit" value="Save" />
                {logoMsg &&
                    <p>{logoMsg}</p>
                }
            </form>

            <br />
            <hr />

            <h3>Edit Front Page Header Image</h3>
            <form onSubmit={updateHeader}>
                <input type="file" accept="image/*" onChange={(e) => setHeader(e.target.files[0])}/>
                <input type="submit" value="Save" />
                {headerMsg &&
                    <p>{headerMsg}</p>
                }
            </form>

        </div>
    );
};

export default Admin;